<template>
  <div>
    <b-card title="Create Person">
      <b-row align-v="center">
        <b-col
          cols="12"
          lg="4"
          class="d-flex justify-content-center"
        >
          <vx-upload
            v-model="PersonData.avatar_id"
            class="mt-2"
          />
        </b-col>
        <b-col
          cols="12"
          lg="8"
        >
          <b-row>
            <b-col
              cols="12"
              lg="6"
            >
              <vx-input
                v-model="PersonData.first_name"
                class="mt-1"
                :label="$t('First Name')"
                :errors="errors.first_name"
              />
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <vx-input
                v-model="PersonData.last_name"
                class="mt-1"
                :label="$t('Last Name')"
                :errors="errors.last_name"
              />
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <vx-input
                v-model="PersonData.phone"
                class="mt-1"
                type="number"
                :label="$t('Phone')"
                :errors="errors.phone"
              />
            </b-col><b-col
              cols="12"
              lg="6"
            >
              <vx-input
                v-model="PersonData.email"
                class="mt-1"
                type="email"
                :label="$t('Email')"
                :errors="errors.email"
              />
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <vx-input
                v-model="PersonData.job_address"
                class="mt-1"
                :label="$t('Desired Job Location')"
                :errors="errors.job_address"
              />
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <vx-input
                v-model="PersonData.qualifications"
                class="mt-1"
                :label="$t('Qualifications')"
                :errors="errors.qualifications"
              />
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <vx-input
                v-model="PersonData.institution_name"
                class="mt-1"
                :label="$t('Institution Name')"
                :errors="errors.institution_name"
              />
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <vs-textarea
                v-model="PersonData.profile_summary"
                label="Profile Summary"
              />
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <vs-textarea
                v-model="PersonData.additional_information"
                label="Additional Information"
              />
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <vs-textarea
                v-model="PersonData.address"
                label="Address"
              />
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <vs-textarea
                v-model="PersonData.ex_address"
                label="Second Address"
              />
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <vs-select
                v-model="PersonData.status"
                :placeholder="$t('Availability')"
                class="selectExample mb-1"
                style="width: 100%"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              >
                <vs-select-item
                  v-for="(item,index) in status"
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                />
              </vs-select>
              <vs-alert
                v-show="errors.status"
                v-if="errors.status"
                color="danger"
                active="true"
              >
                {{ $t(errors.status[0]) }}
              </vs-alert>
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <vs-select
                v-model="PersonData.employment_type"
                :placeholder="$t('Employment Type')"
                class="selectExample mb-1"
                style="width: 100%"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              >
                <vs-select-item
                  v-for="(item,index) in employment_type"
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                />
              </vs-select>
              <vs-alert
                v-show="errors.employment_type"
                v-if="errors.employment_type"
                color="danger"
                active="true"
              >
                {{ $t(errors.employment_type[0]) }}
              </vs-alert>
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-checkbox
                v-model="PersonData.work_permit"
                class="custom-control-primary"
              >
                Do you have work premit
              </b-form-checkbox>
              <b-form-checkbox
                v-model="PersonData.refugee_status"
                class="custom-control-primary mt-1"
              >
                Do you have Refugee Status
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          class="mt-1"
          vs-w="12"
          align="end"
        >
          <vs-button
            color="success"
            class="mr-2 mb-2"
            type="gradient"
            @click="CreatePerson"
          >
            {{ $t('Confirm') }}
          </vs-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    employment_type: [
      { text: 'Full Time', value: 'full_time' },
      { text: 'Part Time', value: 'part_time' },
      { text: 'Remote', value: 'remote' },
    ],
    status: [
      { text: 'Available', value: 'available' },
      { text: 'Unavailable', value: 'unavailable' },
    ],
    errors: [],
    PersonData: {
      avatar_id: null,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      job_address: '',
      qualifications: '',
      institution_name: '',
      profile_summary: '',
      additional_information: '',
      address: '',
      ex_address: '',
      status: '',
      employment_type: false,
      work_permit: false,
    },
  }),
  computed: {
  },
  mounted() {
  },
  methods: {
    CreatePerson() {
      this.errors = []
      this.$store.dispatch('employee/create', this.PersonData).then(() => {
        this.Reset()
      }).catch(error => {
        if (error.response.data.errors) this.errors = error.response.data.errors
      })
    },
    Reset() {
      this.$router.push({ name: 'person' })
    },
  },
}
</script>
<style>
.select-label-placeholder {
  position: absolute;
}
</style>
